body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.modebar-btn {
  font-size: 20px !important;
}

a.modebar-btn.active {
  background-color: gray;
  border-radius: 10px;
}

.blockType {
  color: #000;
}

::-webkit-scrollbar {
  display: none;
}
